import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { Menu, DialogBox, DialogBoxFooterType } from "~/core";
import { messages } from "../../../context-menus/i18n-messages";
import * as fieldActions from "../../../../components/field-module/actions";
import * as analysisModels from "~/recs-events/analysis/model";
import * as cdSelectors from "~/customer-data/selectors";
import { ICustomerFieldMapping } from "~/customer-data/models";
import { eventListSelectors } from "../../../event-module";
import { eventsSelectors } from "~/recs-events";
import * as analysisInfoActions from "../analysis-info/actions";
import { isImportedHarvest } from "../../utils";

import _ from "lodash";
import { AgEventSummary } from "~/recs-events/events/models";
import { NORMALIZED_YIELD_BATCH, NORMALIZED_YIELD_BENCHMARK } from "../analysis-info/interfaces";

const MAX_LAYER_ITEM_EXPAND_COUNT = 25;

interface IProps {
    accordionItemsCount: number;
    expandedCount: number;
    intl: intlShape;
    onAddAnalysisLayer: (
        fieldGuids: string[],
        analysisLayerType: string,
        cropGuid?: string,
        croppingSeasonGuid?: string
    ) => void;
    onClearSelected: () => void;
    onCollapseAll: () => void;
    onExpandAll: () => void;
    selectedCount: number;
    selectedCustomerFields: ICustomerFieldMapping[];
    userInfo: any;
    selectedEventGuidSet: Set<string>;
    selectedEventSummary: Map<string, AgEventSummary>;
    onSetNormalizedYieldType: (normalizedYieldType: string) => void;
}

interface IState {
    errorMessage: string | null;
}

class OperationalLayersContextMenu_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessage: null,
        };
    }

    private addAnalysisLayerProfitLoss() {
        const {
            selectedCustomerFields,
            selectedEventGuidSet,
            selectedEventSummary,
            onAddAnalysisLayer,
        } = this.props;

        if (selectedCustomerFields.length === 0) {
            this.setState({ errorMessage: messages.selectAtLeastOneFieldError });
            return;
        }

        let errorMessage: string | null = null;
        let cropGuid: string | null = null;
        let croppingSeasonGuid: string | null = null;
        const fieldGuids: string[] = [];

        if (selectedEventGuidSet != null && selectedEventGuidSet.size > 0) {
            const eventSelectionFieldGuids: string[] = [];
            const eventSelectionCropGuids: string[] = [];
            const eventSelectionCroppingSeasonGuids: string[] = [];
            for (const eventGeneralGuid of selectedEventGuidSet) {
                const event: AgEventSummary = selectedEventSummary.has(eventGeneralGuid)
                    ? selectedEventSummary.get(eventGeneralGuid)
                    : null;
                if (
                    event.agEventTypeName === "Harvest" &&
                    event.isImportedYn &&
                    event.cropGuids &&
                    event.cropGuids.length > 0 &&
                    event.croppingSeasonGuid != null
                ) {
                    eventSelectionCropGuids.push(event.cropGuids[0]);
                    eventSelectionCroppingSeasonGuids.push(event.croppingSeasonGuid);
                    eventSelectionFieldGuids.push(event.fieldGuid);
                }
            }

            fieldGuids.push(...new Set(eventSelectionFieldGuids));

            const cropAndSeasonsAreNotAllTheSame = !(
                eventSelectionCroppingSeasonGuids.length !== 0 &&
                eventSelectionCropGuids.length !== 0 &&
                eventSelectionCroppingSeasonGuids.every(
                    (c) => eventSelectionCroppingSeasonGuids[0] === c
                ) &&
                eventSelectionCropGuids.every((c) => eventSelectionCropGuids[0] === c)
            );
            const moreThanOneEventSelectedPerField =
                eventSelectionFieldGuids.length !== fieldGuids.length;

            switch (true) {
                case cropAndSeasonsAreNotAllTheSame:
                    errorMessage = messages.harvestEventsAcrossAllFieldsMustBeSameCropAndSeason;
                    break;
                case moreThanOneEventSelectedPerField:
                    errorMessage = messages.moreThanOneEventSelectedPerField;
                    break;
                default:
                    // No error, set crop and cropping season
                    cropGuid = eventSelectionCropGuids[0];
                    croppingSeasonGuid = eventSelectionCroppingSeasonGuids[0];
            }
        } else {
            fieldGuids.push(...selectedCustomerFields.map((x) => x.fieldGuid));
        }

        if (errorMessage) {
            this.setState({ errorMessage: errorMessage });
        } else {
            onAddAnalysisLayer(
                fieldGuids,
                analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS,
                cropGuid,
                croppingSeasonGuid
            );
        }
    }

    private isAnyImportedHarvestEventSelected(isBenchmark) {
        const importHarvestFieldGuidList: string[] = [];
        const { selectedEventSummary, selectedEventGuidSet } = this.props;

        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = selectedEventSummary.has(eventGeneralGuid)
                ? selectedEventSummary.get(eventGeneralGuid)
                : null;
            if (isImportedHarvest(event)) {
                importHarvestFieldGuidList.push(event.fieldGuid);
            }
        }
        if (importHarvestFieldGuidList.length > 0) {
            this.startNormalizedYield(importHarvestFieldGuidList, isBenchmark);
        } else {
            this.setState({ errorMessage: messages.selectEventError });
        }
    }

    private startNormalizedYield(importHarvestFieldGuidList: string[], isBenchmark: boolean) {
        const { onAddAnalysisLayer, onSetNormalizedYieldType } = this.props;
        const uniqueFieldGuids = [...new Set(importHarvestFieldGuidList)];
        const analysisLayerType = isBenchmark
            ? analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD_BENCHMARK
            : analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD;
        const normalizedYieldType = isBenchmark
            ? NORMALIZED_YIELD_BENCHMARK
            : NORMALIZED_YIELD_BATCH;
        onSetNormalizedYieldType(normalizedYieldType);
        onAddAnalysisLayer(uniqueFieldGuids, analysisLayerType);
    }

    private onDialogBoxClose() {
        this.setState({
            errorMessage: null,
        });
    }

    private getSubMenuItems() {
        const { selectedCustomerFields, onAddAnalysisLayer, userInfo } = this.props;
        const { formatMessage } = this.props.intl;
        const customerGuids = selectedCustomerFields.map((x) => x.customerGuid);
        const fieldGuids = selectedCustomerFields.map((x) => x.fieldGuid);
        const layerTypes = userInfo.layerTypesAccess.map((x) => x.name);
        const subItems = [];

        if (layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_EC_DATA)) {
            subItems.push({
                disabled: _.uniq(customerGuids).length !== 1 || fieldGuids.length < 2,
                label: formatMessage(messages.multiFieldEcDataText),
                action: () =>
                    onAddAnalysisLayer(fieldGuids, analysisModels.ANALYSIS_INFO_NAME_EC_DATA),
            });
        }
        if (layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD)) {
            subItems.push({
                label: formatMessage(messages.normalizedYieldText),
                action: () => this.isAnyImportedHarvestEventSelected(false),
            });
        }
        if (layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD_BENCHMARK)) {
            subItems.push({
                label: formatMessage(messages.normalizedYieldBenchmarkText),
                action: () => this.isAnyImportedHarvestEventSelected(true),
            });
        }
        if (layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS)) {
            subItems.push({
                label: formatMessage(messages.profitLossText),
                action: () => this.addAnalysisLayerProfitLoss(),
            });
        }
        return subItems;
    }

    private getMenuItems() {
        const { accordionItemsCount, expandedCount, selectedCount, userInfo } = this.props;
        const { formatMessage } = this.props.intl;

        const items = [];
        const userRole = userInfo.role;
        const layerTypes = userInfo.layerTypesAccess.map((x) => x.name);

        if (userRole.batchAnalysisLayers) {
            if (
                layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_EC_DATA) ||
                layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD) ||
                layerTypes.includes(analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP)
            ) {
                items.push({
                    label: formatMessage(messages.newBatchAnalysis),
                    subMenuItems: this.getSubMenuItems(),
                });
            }
        }

        return items.concat(
            [
                {
                    disabled:
                        accordionItemsCount > MAX_LAYER_ITEM_EXPAND_COUNT ||
                        accordionItemsCount === expandedCount,
                    label: formatMessage(messages.expandAll),
                    action: () => this.props.onExpandAll(),
                },
                {
                    disabled: expandedCount === 0,
                    label: formatMessage(messages.collapseAll),
                    action: () => this.props.onCollapseAll(),
                },
                {
                    disabled: selectedCount === 0,
                    label: formatMessage(messages.clearSelected),
                    action: () => this.props.onClearSelected(),
                },
            ].map((menuItem, key) => {
                return { ...menuItem, key };
            })
        );
    }

    render() {
        const { errorMessage } = this.state;
        const { formatMessage } = this.props.intl;
        const hasError = errorMessage !== null;
        return (
            <div className="context-menu-container" onClick={(evt) => evt.preventDefault()}>
                <Menu
                    className="context-menu"
                    isDotMenu={true}
                    getMenuItems={() => this.getMenuItems()}
                />
                {hasError ? (
                    <DialogBox
                        footerType={DialogBoxFooterType.NONE}
                        isOpen={true}
                        onClose={() => this.onDialogBoxClose()}
                        title={formatMessage(messages.required)}
                    >
                        <div>{formatMessage(errorMessage)}</div>
                    </DialogBox>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedCustomerFields: cdSelectors.getSelectedCustomerFields(state),
    selectedEventGuidSet: eventListSelectors.getSelectedEventGuidSet(state),
    selectedEventSummary: eventsSelectors.getEventGeneralGuidToEventSummaryMap(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetNormalizedYieldType: (normalizedYieldType) =>
        dispatch(analysisInfoActions.setNormalizedYieldType(normalizedYieldType)),
    onAddAnalysisLayer: (
        fieldGuids: string[],
        analysisLayerType: string,
        cropGuid: string = null,
        croppingSeasonGuid: string = null
    ) =>
        dispatch(
            fieldActions.addAnalysisLayer(
                fieldGuids,
                analysisLayerType,
                cropGuid,
                croppingSeasonGuid
            )
        ),
});

export const OperationalLayersContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(OperationalLayersContextMenu_));
